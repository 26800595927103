<template>
  <div :class="{add_address:true,add_address2:altype==false}">
      <div class="bag" @click="adFadeOut"></div>
      <div class="f_cont">
          <a href="javascript:;" class="close" @click="adFadeOut"><img src="../../assets/images/closeiconf1.png" alt=""></a>
          <div class="tit basefont30">{{title}}</div>
          <form action="" class="f_form">
              <div class="item">
                  <input type="text" v-model="form.name" name="name" placeholder="* 收货人">
              </div>
              <div class="item">
                  <input type="text" v-model="form.tel" name="tel" placeholder="* 手机号码">
              </div>
              <div class="f_jut">
                  <div class="item">
                      <select name="modelField[addressshen]" id="province" @change="changeProvince()" v-model="form.sheng">
                        <option value="">请选择省</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in mapString" :key="index">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="item">
                      <select name="modelField[addressshi]" id="city" @change="changeCity()" v-model="form.shi">
                        <option value="">请选择市</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in cityString" :key="index">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="item">
                      <select name="modelField[addressqu]" id="region" v-model="form.qu">
                        <option value="">请选择区</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in districtString" :key="index">{{item.label}}</option>
                    </select>
                  </div>
              </div>
              <div class="item">
                  <input type="text" v-model="form.adds" name="adds" placeholder="* 详细地址">
              </div>
              <button @click.prevent="toggleSubmit()" class="hm-but f_but">
                  <span>保存地址</span>
                  <i>
                      <img src="../../assets/images/jiantouyo1.png" >
                      <img src="../../assets/images/jiantouyo2.png" >
                  </i>
              </button>
          </form>
      </div>
      
  </div>
</template>

<script>
import ditu from '@/assets/js/ditu.json';

export default {
  props:{
    title: String,
    togglecompile:{
      type: Number,
      default: 0,
      required: true,
    },
    arrayCom: Object,
    altype: Boolean
  },
  data(){
    return {
      mapString: ditu, //省份
       cityString: [], //市
       districtString: [], //区
       form:{ //表单内容
            name: "",
            tel: "",
            sheng: "",
            shi: "",
            qu: "",
            adds: ""
        },
    }
  },
  mounted(){
    
    
  },
  methods:{
    
    /* 切换保存和编辑地址 */
    toggleSubmit(){
      if(this.togglecompile==0){
        /* 添加地址 */
        this.arrayCom=[];
        this.addReceivingAddress();
      }else{
        /* 编辑地址 */
        this.compileAddress();
      }
      
    },
    /* 编辑地址 */
    compileAddress(){
        if(this.form.name==""){
            this.$message('收货人不能为空');
            return false;
        }
        var re = /^1\d{10}$/;
      if(this.form.tel==""){
          this.$message({
              message: '手机号码不能为空',
              type: 'warning',
          });
          return false;
      }
      if(!re.test(this.form.tel)){
              this.$message({
                  message: '手机号码输入错误',
                  type: 'warning',
              });
              return false;
      }
      if(this.form.adds==""){
          this.$message('地址不能为空');
          return false;
      }
      this.$axios.post('/api2/portal/auth/user/address/update',{
        id:this.arrayCom.id,
        /* 收货姓名 */
        receiveName: this.form.name,
        /* 手机号码 */
        phone: this.form.tel,
        /* 省份 */
        province: this.form.sheng,
        /* 市 */
        city: this.form.shi,
        /* 区 */
        region: this.form.qu,
        /* 详细地址 */
        detailAddress: this.form.adds,
      }).then(res=>{
        if(res.code=="000000"){
          this.$message({
              message: '编辑成功！',
              type: 'success'
          });
          this.adFadeOut();
          for(let v in this.form){
            this.form[v]="";
          }
          this.updataAddress();
        }
      }).catch(error=>{
        console.log(error);
      });
    },
    /* 保存地址 */
    addReceivingAddress:function(){
        if(this.form.name==""){
            this.$message('收货人不能为空');
            return false;
        }
        var re = /^1\d{10}$/;
      if(this.form.tel==""){
          this.$message({
              message: '手机号码不能为空',
              type: 'warning',
          });
          return false;
      }
      if(!re.test(this.form.tel)){
              this.$message({
                  message: '手机号码输入错误',
                  type: 'warning',
              });
              return false;
      }
      if(this.form.adds==""){
          this.$message('地址不能为空');
          return false;
      }
      this.$axios.post('/api2/portal/auth/user/address/insert',{
        /* 收货姓名 */
        receiveName: this.form.name,
        /* 手机号码 */
        phone: this.form.tel,
        /* 省份 */
        province: this.form.sheng,
        /* 市 */
        city: this.form.shi,
        /* 区 */
        region: this.form.qu,
        /* 详细地址 */
        detailAddress: this.form.adds,
        /* 是否默认 */
        defaultStatus: 1
      }).then(res => {
        if(res.code=="000000"){
          this.$emit("addSub");
          this.$message({
              message: '添加成功！',
              type: 'success'
          });
          this.adFadeOut();
          for(let v in this.form){
            this.form[v]="";
          }
          this.updataAddress();
        }
      }).catch(error=>{
        console.log(error);
      });
    },
    /* 更新地址 */
    updataAddress(){
      this.$emit("updataAdd",true);
    },
    /* 隐藏表单 */
    adFadeOut(){
      this.$emit("adFadeOut", false);
    },
    /* 省份联动 */
    changeProvince(){
        if(this.form.sheng!=""){
            this.mapString.filter(v=>{
                if(v.value==this.form.sheng){
                    this.cityString = v.children;
                    this.districtString = [];
                    this.form.shi="";
                    this.form.qu="";
                }
            });
        }else{
            this.form.shi="";
            this.form.qu="";
            this.cityString = [];
            this.districtString = [];
        }
        
    },
    /* 市 */
    changeCity(){
         this.cityString.filter(v=>{
            if(v.value==this.form.shi){
               this.districtString = v.children;
               this.form.qu="";
            }
        });
    }
  },
  watch:{
    arrayCom(){
      console.log(this.togglecompile);
      this.form.name=this.arrayCom.receiveName;
      this.form.tel=this.arrayCom.phone;
      this.form.sheng=this.arrayCom.province;
      this.changeProvince();
      this.form.shi=this.arrayCom.city;
      this.changeCity();
      this.form.qu=this.arrayCom.region;
      this.form.adds=this.arrayCom.detailAddress;
      console.log(this.form);
    }
  }
}
</script>
