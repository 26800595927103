<template>
  <div class="address1">
    <div class="f_tit basefont30">
        收货地址
    </div>
    <div class="cont">
        <div class="item tit">
            <div class="name na1" >收件人姓名</div>
            <div class="name" >地区</div>
            <div class="name">详细地址</div>
            <div class="name">联系方式</div>
            <div class="name na4">操作</div>
        </div>
        <template v-for="(item,index) in address">
            <div :class="{item: true,on: curo==index}" :key="index">
                <div class="name na1">
                    {{item.receiveName}}
                </div>
                <div class="name">{{item.province}}</div>
                <div class="name">{{item.detailAddress}}</div>
                <div class="name">{{item.phone}}</div>
                <div class="name na4">
                    <div class="f_ti">
                        <a href="javascript:;" @click="compileShow(item)" class="li">编辑</a>
                        <a href="javascript:;" @click="removeAddress(index)" class="li">删除</a>
                    </div>
                    <a href="javascript:;" @click="getDefaultAddress(item.id,index)" class="default1">设为默认</a>
                </div>
            </div>
        </template>
        
    </div>
    <a href="javascript:;" @click="addSite()" class="hm-but f_but">
        <span>+ 添加收货地址</span>
    </a>
    <!-- 添加地址 -->
    <transition name="fade">
        <!-- 
          添加地址标题
          title 参数类型字符串String
          togglecompile 参数类型Number 为0的时候代表是添加收货地址  为1的时候是编辑收货地址
          arrayCom 参数类型object 当为添加收货地址的时候为空  编辑的时候传递数据
          altype  参数类型Boolean  为true的时候  是弹窗形式  否则是固定的形式
          updataAdd 函数  返回值为true  要更新数据因为这是表示已经编辑或者保存了地址
          adFadeOut 函数  返回为false  表示隐藏菜单
          -->
        <AddressAltForm 
        :title = "addressTitle"
        :togglecompile = "compile"
        :arrayCom = "arrayCom"
        :altype = "true"
        @updataAdd = "refresh"
        @adFadeOut = "addressHide"
        v-if="adFade" 
    ></AddressAltForm>
    </transition>
    
  </div>
</template>

<script>
import AddressAltForm from '@/components/addressAltForm/AddressAltForm.vue';
export default {
  name: "Myaddress",
  components: {
    AddressAltForm
  },
  data(){
    return {
        adFade: false,
        addressTitle: "添加收货地址",
        curo: 0,
        address:[],
        compile: 0,
        arrayCom: {}
    }
  },
  computed:{
      addressAxips:function(){
          return this.address;
      }
  },
  mounted(){
    /*  */
    this.getShipping();
  },
  methods: {
    /* 默认设置 */
    getDefaultAddress(_id,_index){
      this.$axios.post('/api2/portal/auth/user/address/default',{
        id: _id
      }).then(res=>{
        console.log(res);
        if(res.code=="000000"){
          this.curo=_index;
          this.$message({
            message: '设置成功！',
            type: 'success'
          });
        }else{
            this.$message({
            message: '设置失败！',
            type: 'error'
          });
        }
      }).catch(error=>{
        console.log(error);
        this.$message({
            message: '接口异常！',
            type: 'error'
          });
      });
    },
    /* 刷新地址 */
    refresh(_val){
        if(_val){
            this.getShipping();
        }
    },
    /* 获取收货地址 */
      getShipping(){
            this.$axios.post("/api2/portal/auth/user/address/myAddress").then(res=>{
                console.log(res);
                let addSite = res.data;
                let lent = addSite.findIndex(v=>{
                    return v.defaultStatus==1;
                });
                var spli1 = addSite.splice(lent,1);
                addSite=[...spli1,...addSite];
                this.address = addSite;
            }).catch(error=>{
                console.log(error);
            });
      },
      /* 编辑收货地址 */
      compileShow(_val){
        this.adFade = true;
        /* 编辑的时候  是1 */
        this.compile=1;
        this.arrayCom=_val;
        this.addressTitle="编辑收货地址";
      },
      /* 隐藏收货地址弹窗 */
      addressHide(_val){
        this.adFade = _val;
      },
      /* 添加收货地址 */
      addSite(){
        this.adFade = true;
        /* 添加地址是0 */
        this.compile=0;
        this.addressTitle="添加收货地址";
      },
      removeAddress:function(index){
         this.$axios.post('/api2/portal/auth/user/address/delete',{id:this.address[index].id}).then(res=>{
            if(res.code=="000000"){
                this.$message({
                    message: '删除成功！',
                    type: 'success'
                });
                this.address.splice(index,1);
            }else{
                this.$message({
                    message: '删除失败！',
                    type: 'error'
                });
            }
         }).catch(error=>{
            this.$message({
                    message: '接口异常！',
                    type: 'error'
                });
            console.log(error);
         });
      },
      
  }
}
</script>


<style lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    
</style>